import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import lazyImport from './lazyImport';
import { CircleToBlockLoading } from 'react-loadingg';

import './App.css';
import './styles/animatedLoadingText.scss';

const Services = lazyImport('./screens/Services');
const About = lazyImport('./screens/About');
const Home = lazyImport('./screens/Home');
const Contact = lazyImport('./screens/Contact');
const Floors = lazyImport('./screens/floors');
const Wallpapers = lazyImport('./screens/wallpapers');

// const LoadingMessage = () => (
//   <div className="loading-message-container">
//     <span className="title">shraddha Interiors</span>
//     <CircleToBlockLoading
//       size="large"
//       style={{ position: 'unset', paddingTop: '20px' }}
//     />
//   </div>
// );

const App = () => {
  return (
    <Router>
      <div>
        <Suspense fallback={<CircleToBlockLoading size="large" />}>
          <Switch>
            <Route path="/services">
              <Services />
            </Route>

            <Route path="/about">
              <About />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/floors">
              <Floors />
            </Route>

            <Route path="/wallpapers">
              <Wallpapers />
            </Route>

            <Route>
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
